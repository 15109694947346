import './vrStyle.css';

export const VrcComponent = () => {

   const goToCall = ()=>{
        window.open('tel:+56961901990');
    }

   const goToMail = ()=>{
        window.open('mailto:jcastroa@ripley.com');
    }

   const goToMap = ()=>{
        window.open('https://www.google.com/maps/place/Alonso+de+C%C3%B3rdova+5320,+7560809+Las+Condes,+Regi%C3%B3n+Metropolitana/@-33.4065809,-70.5769444,17z/data=!3m1!4b1!4m6!3m5!1s0x9662cf7eedfb30cb:0x2bb6bf33b0ea5094!8m2!3d-33.4065854!4d-70.5743641!16s%2Fg%2F11ldtydq43?entry=ttu');
    }

    return(
        <>
            <div className="container-vrc">
                <div className='cont-img-background'>
                    <img src="./ripley.jpg" alt="" />
                </div>
                
                <div className='content-data'>
                    <div className='container-img-principal-vrc'>
                        <img src="./johanna.jpg" alt="" />
                    </div>
                    <div className='personal-data'>
                        <h1>Johanna Castro A.</h1>
                        <p className='cargo-vrc'>Jefe de Seguros corporativo, CHILE y PERU</p>
                        <p className='empresa-vrc'>RIPLEY CORP.</p>
                        <p className='description-vrc'>
                        Una profesional con más de 10 años de experiencia en el ámbito de los seguros para grandes empresas. Actualmente, me desempeño como Jefa de Seguros Corporativo en Ripley, una de las principales compañías de retail en Chile.
                        </p>
                    </div>
                    
                    <div className='contact-data'>
                        <p>Contáctame</p>
                        <div className='container-personal-vrc'>
                            <div className='cont-square-media-vrc' onClick={goToCall}>
                                <span class="material-symbols-outlined">call</span>
                            </div>
                            <div className='cont-square-media-vrc' onClick={goToMail}>
                                <span class="material-symbols-outlined">mail</span>
                            </div>
                            <div className='cont-square-media-vrc' onClick={goToMap}>
                                <span class="material-symbols-outlined">location_on</span>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </>
    );
};