
import './App.css';
import {VrcComponent} from './components/vrc/VrcComponent';

function App() {
  return (
    <div className="App">
      <VrcComponent></VrcComponent>
    </div>
  );
}

export default App;
